<template>
  <div class="insight-main">
    <div class="insight-head-wrapper">
      <div class="insight-header">
        <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
      </div>
      <div class="insight">
        <div class="insight-text">
          <h1 class="insight-heading">
            Insight
          </h1>
          <p class="insight-sub__heading">
            Stay on top of the trends and unlock fresh insight developed by industry executives
          </p>
        </div>
        <div class="insight-cards">
          <div class="insight-card" v-for="(insight, index) in insights" :key="index">
            <a :href="baseUrl + insight.field_attachments" target="_blank">
              <InsightCard :image="baseUrl + insight.field_article_image" />
            </a>
          </div>
        </div>
      </div>
      <div class="subscribe-bar">
        <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
      </div>
    </div>
    <div class="insight-footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import { mapActions, mapState } from 'vuex'
import { Header, InsightCard, Footer, SubscribeBar } from '@/components'
export default {
  name: 'ContactPage',
  components: {
    Footer,
    InsightCard,
    Header,
    SubscribeBar
  },
  data() {
    return {
      baseUrl: BACKEND_BASE_IMG_PATH,
      showSusbscribeBar: true
    }
  },
  methods: {
    ...mapActions('home', ['getInsights']),
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  },
  computed: {
    ...mapState('home', ['insights'])
  },
  created() {
    this.getInsights()
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.insight-main {
  font-family: GothamMedium;
  .insight-head-wrapper {
    min-height: calc(80vh);
  }
  .insight-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: $primary-white;
    z-index: 10;
  }
  .insight {
    margin-top: 130px !important;
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include ipad-10-portrait {
      margin: 130px 66px 30px 50px !important;
    }
    @include iphone-portrait {
      margin: 25px 15px;
    }
    @include ipad-portrait {
      margin-left: 51px;
      margin-right: 51px;
    }
    @include ipad-default-portrait {
      margin: 50px 51px;
    }
    &-heading {
      font-size: 32px;
      font-weight: 500;
      color: $heading-black;
      animation: left-right-transition 2s;
    }
    &-sub__heading {
      width: 57%;
      color: $light-gray;
      animation: left-right-transition 2s;
      font-size: 18px;
      font-family: GothamMedium;
      @include iphone-portrait {
        width: 100%;
      }
    }
    &-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, 24.1%);
      justify-content: space-between;
      @include iphone-portrait {
        grid-template-columns: repeat(auto-fill, 100%);
      }
      @include ipad-10-portrait {
        grid-template-columns: repeat(auto-fill, 32.5%);
      }
      @include ipad-portrait {
        grid-template-columns: repeat(auto-fill, 32.3%);
      }
      @include ipad-default-portrait {
        grid-template-columns: repeat(auto-fill, 32.2%);
      }
      @media (min-width: 1025px) and (max-width: 1279px) {
        grid-template-columns: repeat(auto-fill, 32.5%);
      }
      .insight-card {
        margin-bottom: 15px;
        aspect-ratio: 1/1;
        @include ipad-portrait {
          margin-right: 0;
          margin-bottom: 10px;
        }
        @include ipad-landscape {
          margin-bottom: 10px;
        }
        @include ipad-default-portrait {
          margin-bottom: 15px;
        }
        @include larger-screens {
          margin-bottom: 25px;
        }
        @include iphone-portrait {
          & ::v-deep .insight-images {
            &__image {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
  @keyframes left-right-transition {
    0% {
      transform: translateX(-75%);
      opacity: 0;
    }
    100% {
      transform: translatX(0);
      opacity: 1;
    }
  }
}
@keyframes bottom-top-transition {
  0% {
    transform: translate3d(0, 100vh, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@mixin insightPage1 {
  .insight-card {
    &:nth-child(4n + 1) {
      animation: bottom-top-transition 2s;
    }
    &:nth-child(4n + 2) {
      animation: bottom-top-transition 2.2s;
    }
    &:nth-child(4n + 3) {
      animation: bottom-top-transition 2.4s;
    }
    &:nth-child(4n + 4) {
      animation: bottom-top-transition 2.6s;
    }
  }
}
@mixin insightPageIpadTransition {
  .insight-card {
    &:nth-child(3n + 1) {
      animation: bottom-top-transition 2.2s;
    }
    &:nth-child(3n + 2) {
      animation: bottom-top-transition 2.4s;
    }
    &:nth-child(3n + 3) {
      animation: bottom-top-transition 2.6s;
    }
  }
}
::v-deep .insight-card {
  @include ipad-landscape {
    flex: 0 0 23% !important;
  }
}
::v-deep .app-insight-card {
  height: 100% !important;
}
::v-deep .insight-images {
  height: 100% !important;
}
::v-deep .insight-images__image {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  @include larger-screens {
    min-width: 100% !important;
  }
}
@include computers {
  @include insightPage1;
}
@include larger-screens {
  @include insightPage1;
}
@include ipad-landscape {
  @include insightPage1;
}
@include ipad-default-portrait {
  @include insightPageIpadTransition;
}
@include ipad-10-landscape {
  @include insightPage1;
}
@include ipad-10-portrait {
  @include insightPageIpadTransition;
}
@include ipad-portrait {
  @include insightPageIpadTransition;
}
</style>
